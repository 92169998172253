<template>
  <div class="current-user-card" v-if="user">
    <Avatar :user="user" />
    <header>
      <h3>{{ user.name || user.username }}</h3>
      <div class="stats" v-if="data">
        <nuxt-link :to="`/users/${user.id}/courses`" class="stat">
          <span>Courses</span>
          <span>{{ data?.courseCount }}</span>
        </nuxt-link>
        <div class="separator" />
        <nuxt-link :to="`/users/${user.id}`" class="stat">
          <span>Runs</span>
          <span>{{ data?.runCount }}</span>
        </nuxt-link>
      </div>
    </header>
    <div class="latest" v-if="run">
      <h4>Latest Run</h4>
      <nuxt-link :to="`/runs/${run.id}`">
        <span v-if="run.run_placements"
          >#{{ run.run_placements.at(0)?.placement }} -</span
        >
        {{ run.courses?.name }}
        on
        {{ formatDate(run.ended_at || run.created_at) }}
      </nuxt-link>
    </div>

    <div class="latest" v-if="course">
      <h4>Latest Course</h4>
      <nuxt-link :to="`/courses/${course.id}`">
        {{ course.name || "Unnamed Course" }}
      </nuxt-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Database } from "@/types/supabase";
import { useQuery } from "@tanstack/vue-query";
import { formatDate } from "../lib/format-date";

type User = Database["public"]["Tables"]["users"]["Row"];

const props = defineProps<{
  user: User;
}>();

const supabase = useClient();

const { data } = useQuery({
  queryKey: computed(() => ["user-card", props.user.id]),
  queryFn: async () => {
    const [courses, runs] = await Promise.all([
      supabase
        .from("courses")
        .select("  id,name, course_users!inner ( user_id )", {
          count: "exact",
        })
        .filter("course_users.user_id", "eq", props.user.id)
        .filter("public", "eq", true)
        .order("created_at", { ascending: false })
        .limit(1),
      supabase
        .from("runs")
        .select(
          ` id, ended_at, created_at, started_at, courses ( name ), run_placements ( placement ),
          run_users!inner ( user_id , user:users(*))`,
          { count: "exact" }
        )
        .filter("run_users.user_id", "eq", props.user.id)
        .not("started_at", "is", null)
        .order("created_at", { ascending: false })
        .limit(1),
    ]);

    if (courses.error || runs.error) throw courses.error || runs.error;

    return {
      course: courses.data.at(0),
      courseCount: courses.count,
      run: runs.data.at(0),
      runCount: runs.count,
    };
  },
});

const run = computed(() => data.value?.run);
const course = computed(() => data.value?.course);
</script>
<style scoped>
.current-user-card {
  display: grid;
  grid-template-rows: var(--grid-4);
  grid-auto-flow: row;

  gap: var(--grid);

  justify-items: center;
  position: relative;

  padding: 0px var(--grid) var(--grid);
}

header {
  display: flex;
  flex-direction: column;
  gap: var(--half-grid);
}

.current-user-card:before {
  content: " ";
  position: absolute;
  inset: 0px;
  top: var(--grid-2);
  background-color: white;
  z-index: -1;
  border-radius: 5px;
}

.stats {
  display: flex;
  flex-direction: row;
  gap: var(--grid);
  align-items: center;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat :last-child {
  font-size: var(--grid);
}

.separator {
  content: " ";
  display: block;
  height: 20px;
  width: 1px;
  background-color: gray;
}

.latest {
  text-align: left;
  width: 100%;
}
</style>
