<template>
  <div
    :style="{
      backgroundImage: `url('${url}')`,
      width: `${width}px`,
      height: `${height}px`,
    }"
  />
</template>
<script lang="ts" setup>
const props = defineProps({
  width: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  padding: {
    type: Number,
  },
  geojson: {
    type: Object,
    required: true,
  },
});

const token =
  "pk.eyJ1IjoiZHN0YXVkaWdlbCIsImEiOiJjazNueTNoNm0xdjZ5M2ZudXY3ZWN4aW0xIn0.tCf460GP2JsHd1DxJ-nMNA";

const url = computed(() => {
  return `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/geojson(${encodeURIComponent(
    JSON.stringify(props.geojson)
  )})/auto/${Math.round(props.width)}x${Math.round(props.height)}?${
    props.padding ? `padding=${props.padding}&` : ""
  }&access_token=${token}`;
});
</script>
<style scoped>
div {
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
</style>
