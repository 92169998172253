<template>
  <div class="load-more" ref="root">
    <Spinner v-if="pending" />
    <button v-else @click="loadMore">Load more</button>
  </div>
</template>
<script lang="ts" setup>
import { useIntersectionObserver } from "@vueuse/core";

const pending = ref(false);
const error = ref();
const root = ref();

useIntersectionObserver(root, ([entry]) => {
  if (entry.isIntersecting) loadMore();
});

const props = defineProps<{
  action: () => Promise<unknown>;
}>();

async function loadMore() {
  if (pending.value) return;

  try {
    pending.value = true;
    await props.action();
  } catch (x) {
    error.value = x;
  } finally {
    pending.value = false;
  }
}
</script>
<style scoped>
.load-more {
  width: 100%;
  min-height: 2em;
  justify-content: center;
  display: flex;
}
</style>
