<template>
  <div class="avatars" :class="{ small }">
    <Avatar
      v-for="(u, index) in innerUsers"
      :key="u.id || index"
      :user="u"
      :small="small"
    />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  users?: { id?: number; avatarUrl?: string | null | undefined }[];
  user?: {
    id?: number;
    avatarUrl?: string | null | undefined;
  };
  small: boolean;
}>();

const innerUsers = computed(() => {
  if (props.user) return [props.user];
  else return props.users?.map((o, i, a) => a[a.length - i - 1]) || [];
});
</script>
<style scoped>
.avatars {
  --offset: var(--grid);
  display: grid;
  grid-template-rows: calc(var(--offset) * 0.2);
  /* Use grid layout */
  grid-auto-flow: row;
  /* Align items in rows */
  grid-row-gap: var(--offset);
  /* Set the space between rows based on --offset */
  align-content: end;
  /* Align items at the end (bottom) of the container */
  min-height: calc(var(--grid) * 2);
  place-content: start;
}

.avatar {
  /* No need for absolute positioning since CSS Grid handles the item placement */
  border: 2px var(--white) solid;
  box-shadow: none;
}
</style>
